import React, { useEffect } from "react";

import "./App.scss";
import {
  BrowserRouter,
  // HashRouter as BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Login from "./components/Screens/Login/Login";
import Main from "./components/Screens/Main/Main";
import authService from "./services/AuthService";
import SplashScreen from "./components/Screens/SplashScreen/SplashScreen";
// import "@geoapify/geocoder-autocomplete/styles/minimal.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/redirect" element={<RedirectHandler />} />
          {/* <Route
            // path="/redirect/:redirectUrl"
            path="/redirect"
            element={
              <div
                style={{
                  backgroundColor: "aqua",
                  width: "100vw",
                  height: "100vh",
                }}
              />
            }
          /> */}
          <Route
            path="/main/:id"
            element={<PrivateRoute component={Main} redirectTo={"/login"} />}
          />
          <Route
            path="/main"
            element={<PrivateRoute component={Main} redirectTo={"/login"} />}
          />
          <Route path="/login/redirect" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const PrivateRoute = ({ component: Component, redirectTo, ...rest }) => {
  const tokenExists = authService.getToken() !== null;

  if (!tokenExists) {
    return <Navigate to={redirectTo} />;
  }

  return <Component {...rest} />;
};

const RedirectHandler = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("url");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);

    if (redirectUrl) {
      if (token) {
        const decodedUrl = decodeURIComponent(redirectUrl);
        const parsedUrl = new URL(decodedUrl);
        const baseUrl = `${parsedUrl.origin}`;

        const redirectPath = `${baseUrl}/token/${token}/redirect?url=${encodeURIComponent(
          parsedUrl.pathname + parsedUrl.search
        )}`;

        window.location.href = redirectPath;
      } else {
        navigate(`/login/redirect?url=${encodeURIComponent(redirectUrl)}`);
      }
    }
  }, []);

  return <SplashScreen />;
};

export default App;
