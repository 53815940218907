import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TextField,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export function ProductsList({ items, setSelectedItem }) { 
    // TODO - Añadir filtros de AOI y categorías por API
  const { i18n } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");
  const [filter, setFilter] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);

  // Función para ordenar por una propiedad dada
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Filtrado por texto y por tag (si se ha seleccionado alguno)
  const filteredItems = items.filter((item) => {
    const textMatch =
      (item.title?.[i18n.language] || "")
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      (item.category || "").toLowerCase().includes(filter.toLowerCase());
    const tagMatch = selectedTag
      ? item.tags?.some(
          (tagItem) => tagItem.tag && tagItem.uuid === selectedTag
        )
      : true;
    return textMatch && tagMatch;
  });

  // Ordenación simple según la propiedad seleccionada
  const sortedItems = filteredItems.sort((a, b) => {
    let aValue = a[orderBy];
    let bValue = b[orderBy];

    if (orderBy === "title") {
      aValue = a.title?.[i18n.language] || "";
      bValue = b.title?.[i18n.language] || "";
    }
    if (aValue < bValue) {
      return order === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Al hacer click en un chip, se filtran los productos por ese tag; si ya está seleccionado, se limpia el filtro
  const handleTagClick = (tagUuid, event) => {
    event.stopPropagation(); // Evita que se dispare el click de la fila
    setSelectedTag((prev) => (prev === tagUuid ? null : tagUuid));
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius:"1rem" }}>
      {/* Campo de búsqueda para filtrar los elementos */}
      {/* <TextField
        variant="outlined"
        placeholder="Buscar..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        sx={{ margin: 2 }}
      /> */}
      <TableContainer sx={{ maxHeight: "75vh" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Imagen</TableCell>
              <TableCell sortDirection={orderBy === "category" ? order : false}>
                <TableSortLabel
                  active={orderBy === "category"}
                  direction={orderBy === "category" ? order : "asc"}
                  onClick={() => handleSort("category")}
                >
                  Categoría
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === "title" ? order : false}>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={() => handleSort("title")}
                >
                  Título
                </TableSortLabel>
              </TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Etiquetas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((item) => (
              <TableRow
                key={item.uuid}
                hover
                onClick={() => setSelectedItem(item)}
                sx={{ cursor: "pointer" }}
              >
                <TableCell>
                  <img
                    src={
                      item.bg_image_uuid?.url ||
                      `/images/pocs_images/${item.title?.en}.png`
                    }
                    alt={item.title?.[i18n.language]}
                    style={{
                      width: 50,
                      height: 50,
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </TableCell>
                <TableCell>
                  <label
                    style={{
                      border: "1px solid black",
                      borderRadius: "1rem",
                      //color: "black",
                      backgroundColor:item.color_bg_1,
                      color:item.color_txt_1,
                      fontWeight: "bold",
                      padding: "0.5rem",
                    }}
                  >
                    {item.category}
                  </label>
                </TableCell>
                <TableCell>{item.title?.[i18n.language]}</TableCell>
                <TableCell>{item.description_short?.[i18n.language]}</TableCell>
                <TableCell>
                  {item.tags?.map((tagItem) => {
                    const isSelected = selectedTag === tagItem.uuid;
                    return (
                      <Chip
                        key={tagItem.uuid}
                        label={"# " + tagItem.tag?.[i18n.language]}
                        onClick={(e) => handleTagClick(tagItem.uuid, e)}
                        // variant="contained"
                        color={isSelected ? "error" : "primary"}
                        variant={isSelected ? "contained" : "contained"}
                        sx={{marginRight:"2px"}}

                      />
                    );
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
