const locale = {
  header_title: "Amianto: censo y calendario de retirada",
  txt_footer_privacity: "Política de privacidad y cookies",
  txt_footer_legal: "Aviso legal",
  txt_description:
    "Los ayuntamientos están obligados a realizar un censo de instalaciones y emplazamientos con amianto, incluyendo un calendario que planifique su retirada, según la Ley 07/2022, de 8 de abril de residuos y suelos contaminados para una economía circular. Cumple con la ley 07/2022 de manera sencilla con nuestra solución\nAgforest proporciona la solución completa para su censo de instalaciones de amianto, utilizando  algoritmos de inteligencia artificial e imágenes satélite para la detección de amianto en su localidad.",
  txt_description_title:
    "Censo de instalaciones con presencia de amianto en ayuntamientos obligatorio según ley 07/2022",
  txt_description_title_information:
    "En esta herramienta podrás obtener la cotización para servicios de teledetección de amianto para ayuntamientos, además, de análisis que te ayudarán a entender y proporcionar información relevante para su cliente",
  log_in: "Iniciar sesión",
  password: "Contraseña",
  user_name: "Nombre de usuario",
};
export default locale;
