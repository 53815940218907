import React from "react";
import {
  Modal,
  Box,
  Fade,
  Typography,
  Button,
  IconButton,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

import en from "./i18n/en.js";
import es from "./i18n/es.js";
import i18n from "../../../../../utils/i18n.js";
import authService from "../../../../../services/AuthService.js";
import "./Item.scss";

const style = {
  maxHeight: "90vh",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw", // Ajusta el tamaño del modal según el contenido
  maxWidth: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  borderRadius: "2rem",
  overflow: "hidden",
  border: "none",
};
i18n.addResourceBundle("en", "item", en);
i18n.addResourceBundle("es", "item", es);

export function Item({ item, setSelectedItem }) {
  const { t, i18n } = useTranslation("item");

  const {
    title,
    category,
    description_long_html,
    description_short,
    url,
    color_bg_1,
    color_txt_1,
    bg_image_uuid,
    tags,
    aoi,
  } = item || {};
  console.log("Item url", url);

  const open = item !== undefined;

  const handleClose = () => {
    setSelectedItem(undefined);
  };

  const parsedUrl = url ? new URL(url) : null;
  const baseUrl = parsedUrl ? `${parsedUrl.origin}` : "";
  const redirectPath = parsedUrl
    ? `${baseUrl}/token/${authService.getToken()}/redirect?url=${encodeURIComponent(
        parsedUrl.pathname + parsedUrl.search
      )}`
    : "#";

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Fade in={open}>
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              backgroundColor: "#030303aa",
              borderRadius: "1rem",
              // padding: "1rem",
              // border: "5px solid " + color_bg_1 + "77",
              color: "white",
              zIndex: "1",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div
            style={{
              position: "absolute",
              top: "1rem",
              left: "1rem",
              maxWidth: "calc(100% - 2rem)",
              backgroundColor: "#030303aa",
              borderRadius: "1rem",
              padding: "1rem",
              border: "5px solid " + color_bg_1 + "77",
              color: "white",
              backdropFilter: "blur(5px) brightness(0.75)",
              overflow: "hidden",
            }}
          >
            <div>{category}</div>
            <h2>{title?.[i18n.language]}</h2>
          </div>
          <img
            src={bg_image_uuid?.url || `/images/pocs_images/${title?.en}.png`}
            // style={{ width: "100%", height: "auto" }}
            style={{
              width: "fit-content",
              alignSelf: "center",
              minWidth: "100%",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: "0",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(3, 3, 3, 0.9)",
                padding: "2rem",
                overflowY: "auto",
                maxHeight: "30vh",
              }}
            >
              {description_long_html?.[i18n.language] ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: description_long_html[i18n.language],
                  }}
                />
              ) : (
                <Typography variant="body1">
                  {description_short?.[i18n.language]}
                </Typography>
              )}
            </div>

            {url && (
              <div className="visit-footer-container">
                {(tags?.length > 0 || aoi !== undefined) && (
                  <div
                    style={{
                      margin: "1rem",
                      gap: "1rem",
                      display: "flex",
                    }}
                  >
                    {tags?.length > 0 && (
                      <div>
                        <Typography>{t("tags")}</Typography>
                        {tags?.map((tag) => (
                          <Chip
                            variant="contained"
                            color="error"
                            label={`# ${tag.tag[i18n.language]}`}
                          />
                        ))}
                      </div>
                    )}
                    {aoi !== undefined && (
                      <div>
                        <Typography>{t("aois")}</Typography>
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={`@ ${aoi.name[i18n.language]}`}
                        />
                      </div>
                    )}
                  </div>
                )}

                <a
                  style={{
                    color: "var(--color-primary-txt)",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  // href={url}
                  href={redirectPath}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      margin: "1rem",
                      minWidth: "10rem",
                      backgroundColor: color_bg_1 + "cc",
                      color: color_txt_1,
                      borderRadius: "1rem",
                    }}
                  >
                    {t("visit")}
                  </Button>
                </a>
              </div>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
