import AppContext from "../../Contexts/AppContext";
import React, { useState, useEffect, useRef, useContext } from "react";
import Header from "../../Interfaces/Header/Header";
import { Item } from "./components/Item/Item.jsx";
import { CardList } from "./CardList.jsx";
import { useParams } from "react-router";
import Footer from "../../Interfaces/Footer/Footer";
import coreService from "../../../services/CoreService";
import "./Main.scss";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import en from "./i18n/en.js";
import es from "./i18n/es.js";
import i18n from "../../../utils/i18n";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { ProductsList } from "../../CustomComponents/ProductsList/ProductsList.jsx";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClearIcon from "@mui/icons-material/Clear";

i18n.addResourceBundle("en", "main", en);
i18n.addResourceBundle("es", "main", es);

const Main = ({}) => {
  const { t } = useTranslation("main");
  const {} = useContext(AppContext);
  let params = useParams();
  const inputRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState();
  const [filter, setFilter] = useState("");

  const [projects, setProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);

  const [products, setProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const availableTags = tags.filter((tag) => !selectedTags.includes(tag));
  const [isLoadingTags, setIsLoadingTags] = useState(false);

  const [aois, setAois] = useState([]);
  const [selectedAoi, setSelectedAoi] = useState("");
  const [selectedAois, setSelectedAois] = useState([]);
  const availableAois = aois.filter((aoi) => !selectedAois.includes(aoi));
  const [isLoadingAois, setIsLoadingAois] = useState(false);
  const [formatProjects, setFormatProjects] = useState("LIST"); // LIST; GRID
  const isFirstRender = useRef(true);

  const fetchData = async (
    fetchFunction,
    setterFunction,
    setterFunctionLoading = () => {},
    params = {}
  ) => {
    try {
      setterFunctionLoading(true);
      let response = await fetchFunction(params);
      setterFunctionLoading(false);
      if (response && response.data !== undefined) {
        setterFunction(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const buildQueryParams = (params) => {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(key, v));
      } else if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    });
    return searchParams.toString();
  };

  const fetchProjects = async (params = {}) => {
    const queryString = buildQueryParams(params);
    return await coreService.get_projects(queryString ? `?${queryString}` : "");
  };
  const fetchTags = async () => await coreService.get_tags();
  const fetchProducts = async () => await coreService.get_products();
  const fetchAois = async () => await coreService.get_aois();

  useEffect(() => {
    fetchData(fetchTags, setTags, setIsLoadingTags);
    fetchData(fetchProducts, setProducts, setIsLoadingProducts);
    fetchData(fetchAois, setAois, setIsLoadingAois);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [selectedAois, selectedTags]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Marcar que el primer render ya ocurrió
      return; // No ejecutar la lógica en el primer render
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [filter]);

  const defaultTagProps = {
    options: availableTags,
    getOptionLabel: (option) => {
      return `#${option.tag[i18n.language]}`;
    },
  };

  const defaultAoiProps = {
    options: availableAois,
    getOptionLabel: (option) => {
      return `@${option.name[i18n.language]}`;
    },
  };

  const normalizeText = (text) => {
    return text
      .normalize("NFD")
      .replace(/[^\w\s]|_/g, "")
      .toLowerCase();
  };

  const handleSearch = () => {
    // console.log("handleSearch");

    fetchData(fetchProjects, setProjects, setIsLoadingProjects, {
      ...(filter &&
        !(filter.startsWith("@") || filter.startsWith("#")) && {
          title: filter,
        }),
      tags_uuid_list: selectedTags.map((tag) => tag.uuid),
      language: i18n.language,
      aoi_uuid_list: selectedAois.map((aoi) => aoi.uuid),
    });
  };

  return (
    <div className="mainDiv">
      <Header
        showHelpButton={false}
        enableToolbar={true}
        enableSearchBar={true}
      />
      <div className="mainPage">
        <Typography variant="h4">{t("contracted_projects")} </Typography>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "0 1rem",
            // margin: "auto",
            // maxWidth: "var(--max-article-width)",
          }}
        >
          <div
            className="most-used-tags-div"
            // style={{ display: "flex", gap: "1rem", alignItems: "center" }}
          >
            {availableTags.length > 0 && (
              <Typography style={{ whiteSpace: "nowrap" }}>
                {t("most_used_tags")}:
              </Typography>
            )}
            <div style={{ width: "100%", overflow: "auto" }}>
              {availableTags
                .sort((a, b) => a.n_times_used - b.n_times_used)
                .map((tag) => (
                  <Tooltip title={t("add_tag")}>
                    <Chip
                      key={tag.uuid}
                      label={`# ${tag.tag[i18n.language]}`}
                      deleteIcon={<AddIcon style={{ color: "inherit" }} />}
                      onClick={() => setSelectedTags((curr) => [...curr, tag])}
                      onDelete={() => setSelectedTags((curr) => [...curr, tag])}
                    />
                  </Tooltip>
                ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              // padding: "1rem",
              margin: "auto",
              width: "100%",
            }}
          >
            {filter.startsWith("#") ? (
              <Autocomplete
                fullWidth
                {...defaultTagProps}
                onChange={(event, newValue) => {
                  console.log("onChange");
                  setSelectedTags((curr) => [...curr, newValue]);
                  setFilter("");
                  setSelectedTag("");
                }}
                onKeyDown={(e) => {
                  if (selectedTag === "" && e.key === "Backspace") {
                    setFilter("");
                  }
                }}
                inputValue={selectedTag}
                onInputChange={(event, newValue, reason) => {
                  // console.log("onInputChange", newValue === "", newValue, reason);
                  if (selectedTag === "" && newValue === "@") {
                    setFilter("@");
                    setSelectedAoi("");
                  } else if (reason !== "blur" && reason !== "reset")
                    setSelectedTag(newValue);
                }}
                // onInputChange={(event, newValue) => setSelectedTag(newValue)}
                renderInput={(params) => {
                  console.log("selectedTag", selectedTag);
                  return (
                    <TextField
                      {...params}
                      label={t("select_a_tag")}
                      variant="outlined"
                      inputRef={inputRef}
                      error={
                        !availableTags.some((tag) =>
                          normalizeText(tag.tag[i18n.language]).startsWith(
                            normalizeText(selectedTag)
                          )
                        )
                      }
                      helperText={
                        availableTags.length === 0
                          ? t("no_tags_available")
                          : !availableTags.some((tag) =>
                              normalizeText(tag.tag[i18n.language]).startsWith(
                                normalizeText(selectedTag)
                              )
                            )
                          ? t("tag_not_found")
                          : ""
                      }
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">#</InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
              />
            ) : filter.startsWith("@") ? (
              <Autocomplete
                fullWidth
                {...defaultAoiProps}
                onChange={(event, newValue) => {
                  setSelectedAois((curr) => [...curr, newValue]);
                  setFilter("");
                  setSelectedAoi("");
                }}
                onKeyDown={(e) => {
                  if (selectedAoi === "" && e.key === "Backspace") {
                    setFilter("");
                  }
                }}
                inputValue={selectedAoi}
                // inputValue={selectedAoi}
                onInputChange={(event, newValue, reason) => {
                  if (selectedAoi === "" && newValue === "#") {
                    setFilter("#");
                    setSelectedTag("");
                  } else if (reason !== "blur" && reason !== "reset")
                    setSelectedAoi(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("select_an_aoi")}
                    variant="outlined"
                    inputRef={inputRef}
                    error={
                      !availableAois.some((aoi) =>
                        normalizeText(aoi.name[i18n.language]).startsWith(
                          normalizeText(selectedAoi)
                        )
                      )
                    }
                    helperText={
                      availableAois.length === 0
                        ? t("no_aois_available")
                        : !availableAois.some((aoi) =>
                            normalizeText(aoi.name[i18n.language]).startsWith(
                              normalizeText(selectedAoi)
                            )
                          )
                        ? t("aoi_not_found")
                        : ""
                    }
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">@</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <TextField
                label={t("search_projects")}
                variant="outlined"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                fullWidth
                inputRef={inputRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Opcional: evita que se recargue el formulario si lo hay
                    handleSearch(); // Llama a tu función deseada
                  }
                }}
                InputProps={{
                  // ...params.InputProps,
                  endAdornment: filter && (
                    <InputAdornment
                      position="end"
                      // style={{ marginRight: "10px" }}
                    >
                      <IconButton
                        onClick={() => setFilter("")}
                        size="small"
                        style={{
                          width: "0.9rem",
                          height: "0.9rem",
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Button
              style={{ height: "56px", width: "200px" }}
              onClick={handleSearch}
              variant="contained"
            >
              {t("search")}
            </Button>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            {selectedTags.map((tag) => (
              <Tooltip title={t("delete_tag")}>
                <Chip
                  key={tag.uuid}
                  label={`# ${tag.tag[i18n.language]}`}
                  onClick={() =>
                    setSelectedTags((curr) => curr.filter((t) => t !== tag))
                  }
                  onDelete={() =>
                    setSelectedTags((curr) => curr.filter((t) => t !== tag))
                  }
                  deleteIcon={<CloseIcon />}
                />
              </Tooltip>
            ))}
            {selectedAois.map((aoi) => (
              <Tooltip title={t("delete_aoi")}>
                <Chip
                  variant="outlined"
                  key={aoi.uuid}
                  label={`@ ${aoi.name[i18n.language]}`}
                  onClick={() =>
                    setSelectedAois((curr) => curr.filter((t) => t !== aoi))
                  }
                  onDelete={() =>
                    setSelectedAois((curr) => curr.filter((t) => t !== aoi))
                  }
                  deleteIcon={<CloseIcon />}
                />
              </Tooltip>
            ))}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          {" "}
          <ToggleButtonGroup
            value={formatProjects}
            exclusive
            onChange={(ev, newView) => {
              setFormatProjects(newView);
            }}
            aria-label="view toggle"
          >
            <ToggleButton value="GRID" aria-label="grid view">
              <AutoAwesomeMosaicIcon />
            </ToggleButton>
            <ToggleButton value="LIST" aria-label="list view">
              <FormatListBulletedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {isLoadingProjects && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {formatProjects == "LIST" ? (
          <ProductsList items={projects} setSelectedItem={setSelectedItem} />
        ) : (
          <CardList items={projects} setSelectedItem={setSelectedItem} />
        )}
        <Divider />
        <Typography variant="h4">{t("other_products")}</Typography>
        {isLoadingProducts && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <CardList items={products} setSelectedItem={setSelectedItem} />
      </div>
      <Item item={selectedItem} setSelectedItem={setSelectedItem} />

      <Footer />
    </div>
  );
};
export default Main;
