import { useContext, useState, useEffect } from "react";
import "./Login.scss";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Notiflix from "notiflix";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Footer from "../../Interfaces/Footer/Footer";
import AppContext from "../../Contexts/AppContext";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { makeStyles } from "@mui/styles";
import Header from "../../Interfaces/Header/Header";
import authService from "../../../services/AuthService";
import jwtDecode from "jwt-decode";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import en from "./i18n/en";
import es from "./i18n/es";
import i18next from "i18next";
import DecryptedText from "../../CustomComponents/DecriptedText/DecripedText";
import CloseIcon from "@mui/icons-material/Close";
import LanguageSwitcher from "../../CustomComponents/LanguageSwitcher/LanguageSwitcher";
import zIndex from "@mui/material/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  paper: {
    opacity: 0,
    "&.visible": {
      transition: "opacity 0.5s ease-in-out",
      opacity: 1,
    },
  },
  button: {
    opacity: 1,
    transition: "opacity 0.5s ease-in-out, transform 0.4s ease-in-out",
    "&.hidden": {
      transition: "opacity 0.5s ease-in-out",
      opacity: 0,
      pointerEvents: "none",
    },
    "&:hover": {
      transition: "opacity 0.5s ease-in-out, transform 0.4s ease-in-out",
      transform: "scale(1.2)",
    },
  },
}));

i18next.addResourceBundle("en", "login", en);
i18next.addResourceBundle("es", "login", es);

export default function Login() {
  const { t } = useTranslation("login");
  // const { redirectUrl } = useParams();

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { baseStyle } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("url");
  const query_params = Object.fromEntries([...searchParams]);
  const classes = useStyles();
  const [formVisible, setFormVisible] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (window.self === window.top) {
      localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);
    }
  }, []);

  const handleClick = () => {
    setFormVisible(true);
  };

  function handleClickShowPassword() {
    return setShowPassword(!showPassword);
  }
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const loginFunction = async () => {
    console.log("Haciendo login");
    setSuccess(false);
    setLoading(true);
    let response = await authService.login(username, password);

    setLoading(false);
    if (response === false || !response.data.hasOwnProperty("token")) {
      Notiflix.Notify.warning("Usuario o contraseña incorrectos");
      return;
    }
    setSuccess(true);
    authService.saveToken(response.data["token"]);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (redirectUrl) {
      const parsedUrl = new URL(decodeURIComponent(redirectUrl));

      const baseUrl = parsedUrl.origin;

      window.location.href = `${baseUrl}/token/${authService.getToken()}/redirect?url=${encodeURIComponent(
        parsedUrl.pathname + parsedUrl.search
      )}`;
    } else
      navigate({
        pathname: "/main/",
        search: createSearchParams(query_params).toString(),
      });
  };

  const SOCIAL_MEDIA_AND_ICONS = [
    {
      label: "Linkedn",
      link: "https://www.linkedin.com/company/agforest/",
      icon: process.env.PUBLIC_URL + "/images/social_media/linkdn.png",
    },

    {
      label: "X",
      link: "https://x.com/Agforest_",
      icon: process.env.PUBLIC_URL + "/images/social_media/twitter_x.png",
    },
    // {
    //   label: "Web",
    //   link: "https://www.agforest.es",
    //   icon: process.env.PUBLIC_URL + "/images/social_media/web.png",
    // },
  ];

  function splitTextIntoParts(text, numParts) {
    const words = text.split(" "); // Dividir el texto en palabras
    const totalWords = words.length;
    const wordsPerPart = Math.floor(totalWords / numParts); // Calcular cuántas palabras deben ir en cada parte

    let parts = [];
    let currentPart = [];
    let wordCount = 0;

    // Repartir las palabras en las partes
    for (let i = 0; i < totalWords; i++) {
      currentPart.push(words[i]);
      wordCount++;

      // Si hemos alcanzado el número de palabras por parte, agregamos la parte y comenzamos una nueva
      if (wordCount >= wordsPerPart && i < totalWords - 1) {
        parts.push(currentPart.join(" ")); // Unir las palabras en un string
        currentPart = [];
        wordCount = 0;
      }
    }

    // Agregar la última parte (si queda alguna palabra)
    if (currentPart.length > 0) {
      parts.push(currentPart.join(" "));
    }

    return parts;
  }

  return (
    <div className="loginPage">
      <LanguageSwitcher
        style={{
          position: "absolute",
          width: "min-content",
          top: "0",
          right: "0",
          zIndex: "9",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          zIndex: 1,
          left: "0rem",
          width: "100%",
          height: "100%",
          backgroundColor: "var(--color-background-light)",
          opacity: "0.3",
        }}
      />
      <video
        className="background"
        loop
        autoPlay={true}
        playnInLine
        muted
        poster="video/realistic-animation-of-a-satellite-in-orbit-of-pla-2022-08-04-14-26-32-utc.png"
      >
        <source
          src={
            "video/low_code_realistic-animation-of-a-satellite-in-orbit-of-pla-2022-08-04-14-26-32-utc.mp4"
          }
          type="video/mp4"
        />
        {t("your_browser_does_not_support_the_video_tag")}
      </video>
      <header>
        <a
          href="https://www.agforest.ai"
          className="header_logo"
          target="_blank"
          rel="noreferrer"
        >
          {/* {!isVerySmallScreen && ( */}
          <img
            src="/images/NEW_LOGO.png"
            alt="logo"
            height="70%"
            className="header_logo_img"
          />
          {/* )} */}
        </a>
      </header>
      {/* <div style={{ display: "flex", gap: "5rem", alignItems: "center" }}> */}
      {!formVisible ? (
        <div className="formContainer">
          <div
            style={{
              // maxWidth: "30rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <label
                className="big_label  div-noBlur"
                style={{ fontSize: "2.6rem" }}
              >
                Building a sustainable future with geospatial datascience
              </label> */}
            {splitTextIntoParts(
              "Building a sustainable future with geospatial datascience",
              3
            ).map((text) => {
              console.log("TEXT", text);
              return (
                <DecryptedText
                  text={text}
                  animateOn="view"
                  revealDirection="start"
                  speed={60}
                  maxIterations={30}
                  style={{ fontSize: "2.6rem" }}
                  sequential={true}
                />
              );
            })}
            <br />
            <DecryptedText
              text="space tech company"
              animateOn="view"
              revealDirection="start"
              speed={60}
              maxIterations={30}
              style={{ fontSize: "1rem", fontWeight: 100 }}
              sequential={true}
            />
          </div>
          <Button
            // className={`${classes.button} ${formVisible ? "hidden" : ""}`}
            style={{
              zIndex: formVisible ? 0 : 500,
              backgroundColor: "rgb(var(--color-secondary-raw))",
              fontWeight: 600,
              color: "black",
              padding: "2rem 4rem",
              fontSize: "1.5rem",
              height: "2rem",
              borderRadius: "25% / 45%",
              textTransform: "none ",
              boxShadow: "10px 10px 20px black",
              width: "fit-content",
            }}
            onClick={handleClick}
          >
            Login
          </Button>
        </div>
      ) : (
        <>
          <Paper
            className={` form_paper ${classes.paper} ${
              formVisible ? "visible" : ""
            }`}
            style={{
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255,255,255,0.13)",
              // backgroundColor: "var(--color-primary-transparent-70)",
              color: "var(--color-primary-txt)",
              position: "absolute",
              left: "50%",
              top: "50%",
              backdropFilter: "blur(10px)",
              border: "2px solid var(--color-secondary-transparent-90)",
              // border: "2px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => setFormVisible(false)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                // color: "var(--color-fourth)",
                // backgroundColor: "lightgray",
              }}
            >
              <CloseIcon />
            </IconButton>
            <label style={{ color: "var(--color-primary-txt) !important" }}>
              <h2>Inicio de sesión</h2>
            </label>
            <TextField
              value={username}
              style={{ width: "100%" }}
              sx={{
                "& p": { color: "var(--color-primary-txt)" },
                ".MuiInputBase-input": {
                  // backgroundColor: "var(--color-primary-txt)",
                  backgroundColor: "rgba(255,255,255,0.07)",
                },
                "& .MuiOutlinedInput-root input": {
                  backgroundColor: "inherit", // Asegura que el fondo del texto se mantenga igual
                },
              }}
              onChange={(e) => {
                setusername(e.target.value);
              }}
              helperText={t("user_name")}
            />
            <TextField
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              value={password}
              helperText={t("password")}
              // variant="filled"
              sx={{
                "& p": { color: "var(--color-primary-txt)" },
                ".MuiInputBase-input": {
                  // backgroundColor: "var(--color-primary-txt)",
                  backgroundColor: "rgba(255,255,255,0.07)",
                },
                "& input:-internal-autofill-selected ": {
                  // backgroundColor: "red!important", // Asegura que el fondo del texto se mantenga igual
                },
                "& .MuiOutlinedInput-root": {
                  // backgroundColor: "#f5f5f5", // Color de fondo por defecto
                  // "&.Mui-focused": {
                  //   backgroundColor: "#e0e0e0", // Color cuando está enfocado
                  // },
                  "& input": {
                    backgroundColor: "inherit", // Asegura que el fondo del texto se mantenga igual
                  },
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      // sx={{
                      //   "&.hover": {
                      //     backgroundColor: "rgb(var(--color-secondary-raw))",
                      //   },
                      // }}
                    >
                      {showPassword ? (
                        <Visibility
                        // style={{ color: "var(--color-primary)" }}
                        />
                      ) : (
                        <VisibilityOff
                        // style={{ color: "var(--color-primary)" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                onClick={loginFunction}
                //type="submit"
                style={{
                  // backgroundColor: "rgb(var(--color-secondary-raw))",
                  fontWeight: 600,
                  // width: "80%",
                }}
                disabled={loading}
                sx={{
                  color: "black",
                  whiteSpace: "nowrap",
                  bgcolor: "var(--color-secondary-light-10)",
                  "&:hover": {
                    bgcolor: "var(--color-secondary-light-30)",
                  },
                  ...(success && {
                    bgcolor: "var(--color-secondary-light-50)",
                    "&:hover": {
                      bgcolor: "var(--color-secondary-light-80)",
                    },
                  }),
                }}
              >
                {t("log_in")}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    // color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Paper>
        </>
      )}
      {/* </div> */}

      <footer
        className="footer2"
        // style={{
        //   display: "flex",
        //   // borderTop: "3px solid rgb(var(--color-secondary-raw))",
        //   backdropFilter: "blur(5px) brightness(0.75)",
        //   justifyContent: "center",
        // }}
      >
        <div className="footer_row">
          <div
            className="footer_column"
            style={{ justifyContent: "flex-start" }}
          >
            <Link
              to="https://www.agforest.ai/legal-disclaimer"
              className="footer_link"
            >
              {t("txt_footer_legal")}
            </Link>
            |
            <Link
              to="https://www.agforest.ai/politica-y-cookies"
              className="footer_link"
            >
              {t("txt_footer_privacity")}
            </Link>
          </div>
          <div className="footer_column">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              {SOCIAL_MEDIA_AND_ICONS.map((e) => {
                return (
                  <Tooltip title={e.label}>
                    <a
                      style={{
                        color: "var(--color-primary-txt)",
                        height: "1.5rem",
                        cursor: "pointer",
                        marginRight: "1rem",
                      }}
                      href={e.link}
                      target="_blank"
                    >
                      <img src={e.icon} style={{ height: "1.5rem" }} />
                    </a>
                  </Tooltip>
                );
              })}
            </div>
            <a
              style={{
                color: "var(--color-primary-txt)",
                height: "100%",
                cursor: "pointer",
              }}
              target="_blank"
              href="https://www.agforest.ai"
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/images/social_media/agforest.png"
                }
                style={{
                  height: "2.2rem",
                }}
              />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
