import React from "react";
import { Grid, Typography, Link, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import en from "./i18n/en.js";
import es from "./i18n/es.js";
import i18n from "../../../utils/i18n";
import "./Footer.scss";

i18n.addResourceBundle("en", "footer", en);
i18n.addResourceBundle("es", "footer", es);

const Footer = () => {
  const { t, i18n } = useTranslation("footer");

  const getLocalizedLink = (link) => {
    return i18n.language === "en"
      ? link.replace("https://agforest.ai", "https://agforest.ai/en")
      : link;
  };

  return (
    <footer className="footer-container">
      <div className="footer-banner">
        <h2 className="footer-title">{t("do_you_want_to_know_more")}</h2>
        <Link
          className="footer-contact"
          href={getLocalizedLink("https://agforest.ai/contacto/")}
        >
          {t("contact_us")}
        </Link>
      </div>
      <div className="footer-grid">
        <img
          src="/images/NEW_LOGO.png"
          alt="AGForest"
          className="footer-logo"
        />
        <div className="footer-grid2">
          <div className="footer-column">
            <Typography className="footer-heading">{t("solutions")}</Typography>
            <Link
              href={getLocalizedLink("https://agforest.ai/censo-de-amianto/")}
            >
              {t("asbestos_census")}
            </Link>
            <Link href={getLocalizedLink("https://agforest.ai/fuga-de-agua/")}>
              {t("water_leaks")}
            </Link>
            <Link
              href={getLocalizedLink(
                "https://agforest.ai/contaminacion-y-gestion-de-residuos/"
              )}
            >
              {t("co2_sinks")}
            </Link>
            <Link
              href={getLocalizedLink("https://agforest.ai/transformacion-esg/")}
            >
              {t("esg_transformation")}
            </Link>
          </div>
          <div className="footer-column">
            <Typography className="footer-heading">
              {t("about_agforest")}
            </Typography>
            <Link href={getLocalizedLink("https://agforest.ai/blog/")}>
              {t("blog")}
            </Link>
            <Link href={getLocalizedLink("https://agforest.ai/sala-de-prensa")}>
              {t("press_room")}
            </Link>
            <Link
              href={getLocalizedLink("https://agforest.ai/casos-de-exito/")}
            >
              {t("success_stories")}
            </Link>
            <Link
              href={getLocalizedLink("https://agforest.ai/sobre-nosotros/")}
            >
              {t("about_us")}
            </Link>
          </div>
          <div className="footer-column">
            <Typography className="footer-heading">{t("contact")}</Typography>
            <a href="tel:+34662328177">+34 662 32 81 77</a>
            <Typography className="footer-text">
              Madrid, {t("spain")}
            </Typography>
            <a href="mailto:comercial@agforest.es">comercial@agforest.es</a>
          </div>
        </div>
        <div className="footer-images">
          <img
            src="/images/pyme_innovadora.png"
            alt="Certificación 1"
            className="footer-cert-image"
          />
          <Typography>{t("innovative_sme")}</Typography>
          <Typography>{t("valid_until")}</Typography>
          <img
            src="/images/gobierno_españa.png"
            alt="Certificación 2"
            className="footer-cert-image-large"
          />
        </div>
      </div>
      <Divider className="footer-divider" />
      <div className="footer-bottom">
        <Typography className="footer-copy">
          © {new Date().getFullYear()} AGForest
        </Typography>
        <div className="footer-links">
          <Link
            href={getLocalizedLink(
              "https://agforest.ai/politica-de-privacidad-y-cookies/"
            )}
          >
            {t("privacyand_cookie_policy")}
          </Link>
          <div>|</div>
          <Link href={getLocalizedLink("https://agforest.ai/aviso-legal/")}>
            {t("legal_notice")}
          </Link>
        </div>
        <img
          src="/images/union_europea.webp"
          alt="union_europea"
          className="footer-eu-logo"
        />
      </div>
    </footer>
  );
};

export default Footer;
