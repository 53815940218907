import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import "./Header.scss";
import LanguageSwitcher from "../../CustomComponents/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import en from "./i18n/en.js";
import es from "./i18n/es.js";
import i18n from "../../../utils/i18n";
import { useNavigate } from "react-router-dom";
i18n.addResourceBundle("en", "header", en);
i18n.addResourceBundle("es", "header", es);

export default function Header({
  style = {},
  className = "",
  showButtons = true,
}) {
  const { t, i18n } = useTranslation("header");
  const isVerySmallScreen = useMediaQuery("(max-width:370px)");
  const isMobile = useMediaQuery("(max-width:1200px)");
  const max600px = useMediaQuery("(max-width:600px)");
  const [anchorElSolutions, setAnchorElSolutions] = useState(null);
  const [anchorElAvatar, setAnchorElAvatar] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  function decodeJWT() {
    const token = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);

    const base64Url = token.split(".")[1]; // Extrae la parte del payload
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Formatea para decodificar
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const decodedTokenPayload = decodeJWT();
  const payloadObject = JSON.parse(decodedTokenPayload.payload);

  const getLocalizedLink = (link) => {
    if (i18n.language === "en") {
      return link.replace("https://agforest.ai", "https://agforest.ai/en");
    }
    return link;
  };

  // URLs de cada sección
  const navLinks = [
    {
      label: "about_us",
      url: getLocalizedLink("https://agforest.ai/sobre-nosotros"),
    },
    {
      label: "press_room",
      url: getLocalizedLink("https://agforest.ai/sala-de-prensa"),
    },
    { label: "blog", url: getLocalizedLink("https://agforest.ai/blog") },
    {
      label: "success_cases",
      url: getLocalizedLink("https://agforest.ai/casos-de-exito"),
    },
    // {
    //   variant: "contained",
    //   color: "white",
    //   label: "contact_us",
    //   marginLeft: "1rem",
    //   url: getLocalizedLink("https://agforest.ai/contacto"),
    // },
  ];

  const solutions = [
    {
      label: "asbestos_census",
      url: getLocalizedLink("https://agforest.ai/censo-de-amianto"),
    },
    {
      label: "water_leaks",
      url: getLocalizedLink("https://agforest.ai/fuga-de-agua"),
    },
    {
      label: "co2_sinks",
      url: getLocalizedLink(
        "https://agforest.ai/contaminacion-y-gestion-de-residuos"
      ),
    },
    {
      label: "esg_transformation",
      url: getLocalizedLink("https://agforest.ai/transformacion-esg"),
    },
  ];

  const handleAvatarMenuOpen = (event) => {
    setAnchorElAvatar(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAnchorElAvatar(null);
  };

  const handleSolutionsMenuOpen = (event) => {
    setAnchorElSolutions(event.currentTarget);
  };

  const handleSolutionsMenuClose = () => {
    setAnchorElSolutions(null);
  };

  const getInitials = (string) =>
    string
      .split("@")[0] // Separa por '@' y toma la primera parte (antes del dominio)
      .split(".") // Separa por '.'
      .map((name) => name[0].toUpperCase()) // Toma la primera letra y la convierte en mayúscula
      .join("");

  return (
    <>
      <AppBar
        position="static"
        className={`header ${className}`}
        style={{
          backgroundColor: "var(--color-background-light)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      >
        <Toolbar
          style={{
            width: "100%",
            maxWidth: "var(--max-article-width)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a
            href="https://www.agforest.ai"
            className="header_logo"
            target="_blank"
            rel="noreferrer"
          >
            {!isVerySmallScreen && (
              <img
                src="/images/NEW_LOGO.png"
                alt="logo"
                height="70%"
                className="header_logo_img"
              />
            )}
          </a>

          {showButtons &&
            (isMobile ? (
              <>
                <IconButton
                  edge="end"
                  color="black"
                  onClick={() => setDrawerOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={max600px ? "top" : "right"}
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                >
                  <List>
                    <ListItem>{t("solutions")}</ListItem>
                    {solutions.map((solution) => (
                      <ListItemButton
                        key={solution.label}
                        component="a"
                        href={solution.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        -
                        <ListItemText
                          primary={t(solution.label)}
                          style={{ marginLeft: "1rem" }}
                        />
                      </ListItemButton>
                    ))}
                    {navLinks.map((link) => (
                      <ListItemButton
                        key={link.label}
                        component="a"
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ListItemText primary={t(link.label)} />
                      </ListItemButton>
                    ))}
                    <ListItemButton
                      component="a"
                      href={getLocalizedLink("https://agforest.ai/contacto")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText primary={t("contact_us")} />
                    </ListItemButton>
                    {/* <ListItem style={{ justifyContent: "flex-end" }}> */}
                    <LanguageSwitcher />
                    {/* </ListItem> */}
                    <ListItemButton
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      {t("log_out")}
                      <ExitToAppIcon />
                    </ListItemButton>
                    <ListItem>{payloadObject.email}</ListItem>
                  </List>
                </Drawer>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                {/* Botón Soluciones con menú desplegable */}
                <div onMouseLeave={handleSolutionsMenuClose}>
                  <Button
                    sx={{ color: "black" }}
                    aria-controls="soluciones-menu"
                    aria-haspopup="true"
                    // onClick={handleMenuOpen}
                    onMouseEnter={handleSolutionsMenuOpen}
                    // onMouseLeave={handleMouseLeave}
                    color="inherit"
                    endIcon={<ArrowDropDownIcon />} // Flechita indicadora
                  >
                    {t("solutions")}
                  </Button>
                  <Menu
                    id="soluciones-menu"
                    anchorEl={anchorElSolutions}
                    open={Boolean(anchorElSolutions)}
                    // onClose={handleMenuClose}

                    onClose={handleSolutionsMenuClose}
                    MenuListProps={{
                      // onMouseEnter: handleMouseEnter,
                      onMouseLeave: handleSolutionsMenuClose,
                    }}
                  >
                    {solutions.map((solution) => (
                      <MenuItem
                        key={solution.label}
                        onClick={handleSolutionsMenuClose}
                        component="a"
                        href={solution.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t(solution.label)}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>

                {/* Botones de navegación */}
                {navLinks.map((link) => (
                  <Button
                    variant={link.variant}
                    sx={{
                      color: link.color ?? "black",
                      marginLeft: link.marginLeft ?? "",
                    }}
                    key={link.label}
                    // color="inherit"
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(link.label)}
                  </Button>
                ))}

                <Button
                  variant="contained"
                  sx={{
                    color: "white",
                    // marginLeft: link.marginLeft ?? "",
                  }}
                  // key={link.label}
                  // color="inherit"
                  href={getLocalizedLink("https://agforest.ai/contacto")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("contact_us")}
                </Button>

                {/* <LanguageSwitcher /> */}
                <Avatar onClick={handleAvatarMenuOpen}>
                  {getInitials(payloadObject.email)}
                </Avatar>
                <Menu
                  id="soluciones-menu"
                  anchorEl={anchorElAvatar}
                  open={Boolean(anchorElAvatar)}
                  onClose={handleAvatarMenuClose}
                  MenuListProps={{
                    onMouseLeave: handleAvatarMenuClose,
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem sx={{ pointerEvents: "none", userSelect: "text" }}>
                    {payloadObject.email}
                  </MenuItem>
                  <LanguageSwitcher callbackFunc={handleAvatarMenuClose} />
                  <MenuItem
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => navigate("/login")}
                  >
                    {t("log_out")}
                    <ExitToAppIcon />
                  </MenuItem>
                </Menu>
              </div>
            ))}
        </Toolbar>
      </AppBar>
    </>
  );
}
