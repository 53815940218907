import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";

export function Card({ item, setSelectedItem, widthPercentage, size }) {
  // console.log("size", size);
  const { i18n } = useTranslation("");
  const {
    id,
    title,
    category,
    color_bg_1,
    color_txt_1,
    description_short,
    bg_image_uuid,
  } = item;
  // return;

  return (
    <Grid
      size={{ xs: 12, sm: size }}
      sx={{
        height: {
          xs: "250px",
          sm: "300px",
          md: "400px",
          lg: "460px",
          xl: "500px",
        },
      }}
      className="card2"
      onClick={() => setSelectedItem(item)}
    >
      <img
        className="card-background-image"
        src={
          bg_image_uuid?.url
            ? bg_image_uuid?.url
            : `/images/pocs_images/${title?.en}.png`
        }
        alt=""
      />
      <div
        style={{
          position: "absolute",
          top: "1rem",
          left: "1rem",
          backgroundColor: "#030303aa",
          borderRadius: "1rem",
          padding: "1rem",
          border: "5px solid " + color_bg_1 + "77",
          color: "white",
          backdropFilter: "blur(5px) brightness(0.75)",
        }}
      >
        <span>{category}</span>
        <h2>{title?.[i18n.language]}</h2>
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
          backgroundColor: `${color_bg_1}cc`,
          padding: "1.5rem",
        }}
      >
        {description_short?.[i18n.language]}
      </div>
    </Grid>
  );

  return (
    <li
      className="card"
      style={{ width: `calc(${widthPercentage}% - 1rem)` }}
      onClick={() => setSelectedItem(item)}
    >
      <img
        className="card-background-image"
        src={
          bg_image_uuid?.url
            ? bg_image_uuid?.url
            : `/images/pocs_images/${title?.en}.png`
        }
        alt=""
      />
      <div
        style={{
          position: "absolute",
          top: "1rem",
          left: "1rem",
          backgroundColor: "#030303aa",
          borderRadius: "1rem",
          padding: "1rem",
          border: "5px solid " + color_bg_1 + "77",
          color: "white",
          backdropFilter: "blur(5px) brightness(0.75)",
        }}
      >
        <span>{category}</span>
        <h2>{title?.[i18n.language]}</h2>
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
          backgroundColor: `${color_bg_1}cc`,
          padding: "1.5rem",
        }}
      >
        {description_short?.[i18n.language]}
      </div>
    </li>
  );
}
