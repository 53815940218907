const locale_header = {
  contracted_projects: "Proyectos contratados",
  other_products: "Otros productos",
  search_projects: "Escribe # para buscar por etiquetas o @ para buscar por AOI",
  select_an_option: "Seleccionar una opción",
  no_tags_available: "No hay etiquetas disponibles",
  tag_not_found: "Etiqueta no encontrada",
  select_a_tag: "Selecciona una etiqueta",
  select_an_aoi: "Selecciona un AOI",
  aoi_not_found: "AOI no encontrado",
  no_aois_available: "No hay AOIs disponibles",
  most_used_tags: "Etiquetas más usadas",
  add_tag: "Agregar etiqueta",
  delete_tag: "Eliminar etiqueta",
  add_aoi: "Agregar AOI",
  delete_aoi: "Eliminar AOI",
  search:'Buscar'
};
export default locale_header;
