import React from "react";
// import { items } from "./data";
import { Card } from "./Card";
import Grid from "@mui/material/Grid2";

export function CardList({ items, setSelectedItem }) {
  return (
    <Grid container spacing={2} style={{ width: "100%", padding: "0 1rem" }}>
      {items.map((item, index, arr) => {
        const isEvenRow = Math.floor(index / 2) % 2 === 0; // Alternancia por fila
        const pairIndex = index % 2 === 0 ? index + 1 : index - 1;
        const next = arr[pairIndex]?.importance || item.importance;

        const bigValue = 6.5;
        const smallValue = 5.5;

        let value;
        if (item.importance > next) {
          value = bigValue;
        } else if (item.importance < next) {
          value = smallValue;
        } else {
          value = isEvenRow
            ? index % 2 === 0
              ? bigValue
              : smallValue
            : index % 2 === 0
            ? smallValue
            : bigValue;
        }

        return (
          <Card
            key={item.uuid}
            item={item}
            setSelectedItem={setSelectedItem}
            // widthPercentage={widthPercentage}
            size={value}
          />
        );
      })}
    </Grid>
  );

  return (
    <ul className="card-list">
      {items.map((item, index, arr) => {
        const current = item.importance;
        const next =
          arr.length === 1
            ? 0
            : items[index % 2 === 0 ? index + 1 : index - 1]?.importance ||
              current;
        const total = current + next;
        const widthPercentage = (current / total) * 100;

        return (
          <Card
            key={item.uuid}
            item={item}
            setSelectedItem={setSelectedItem}
            widthPercentage={widthPercentage}
          />
        );
      })}
    </ul>
  );
}
