import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import AppContext from "../../Contexts/AppContext";

export default function LanguageSwitcher({
  callbackFunc = () => {},
  ...props
}) {
  const { i18n } = useTranslation("");
  useEffect(() => {
    localStorage.setItem("language", i18n.language);
  }, [i18n.language]);
  const { localStyle } = useContext(AppContext);
  const [menu, setMenu] = useState(null);

  const languages = [
    { id: "en", title: "English", flag: "UK" },
    { id: "es", title: "Español", flag: "ES" },
    // { id: "ro", title: "Română", flag: "RO" },
  ];

  const currentLanguage = languages.find((lang) => lang.id === i18n.language);

  if (currentLanguage === undefined) return;

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  // const localStorageColorHEX = localStyle.baseColor1;
  const localStorageColorHEX = "#000000";
  const localStorageColorRGB = hexToRgb(localStorageColorHEX);
  const brightness = Math.round(
    (parseInt(localStorageColorRGB.r) * 299 +
      parseInt(localStorageColorRGB.g) * 587 +
      parseInt(localStorageColorRGB.b) * 114) /
      1000
  );

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
    callbackFunc();
  };

  function handleLanguageChange(lng) {
    if (props.setUserMenu) {
      props.setUserMenu(false);
    }
    i18n.changeLanguage(lng.id);

    langMenuClose();
  }

  return (
    <>
      <MenuItem
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
        // className="h-40 w-64"
        onClick={langMenuClick}
        {...props}
      >
        {currentLanguage.title}
        <img
          src={
            window.location.origin + `/images/flags/${currentLanguage.flag}.svg`
          }
          alt={currentLanguage.title}
        />
      </MenuItem>
      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {languages.map((lng) => (
          <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
            <ListItemIcon className="min-w-40">
              <img
                className="min-w-20"
                src={window.location.origin + `/images/flags/${lng.flag}.svg`}
                alt={lng.title}
              />
            </ListItemIcon>
            <ListItemText primary={lng.title} />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
