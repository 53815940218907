const locale = {
  do_you_want_to_know_more: "Do you want to know more?",
  contact_us: "Contact us",
  solutions: "Solutions",
  asbestos_census: "Asbestos census",
  water_leaks: "Water leaks",
  co2_sinks: "CO2 sinks",
  esg_transformation: "ESG transformation",
  about_agforest: "About AGForest",
  blog: "Blog",
  press_room: "Press room",
  success_stories: "Success stories",
  about_us: "About us",
  contact: "Contact",
  innovative_sme: "Innovative SME",
  valid_until: "Valid until May 24, 2027",
  privacyand_cookie_policy: "Privacy and cookie policy",
  legal_notice: "Legal notice",
  spain: 'Spain'
};
export default locale;
