import axios from "axios";
import { jwtDecode } from "jwt-decode";
import authService from "./AuthService";

const URL_BASE = process.env.REACT_APP_API_URL;

const coreService = {
  // Llamada HTTP para obtener el token
  async get_projects(queryString) {
    const config = {
      method: "get",
      url: `${URL_BASE}/core/projects${
        queryString.toString() ? `${queryString.toString()}` : ""
      }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authService.getToken()}`,
      },
    };

    return axios
      .request(config)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },

  async get_tags() {
    const config = {
      method: "get",
      url: URL_BASE + "/core/user_tags",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authService.getToken()}`,
      },
    };

    return axios
      .request(config)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },

  async get_products() {
    const config = {
      method: "get",
      url: URL_BASE + "/core/products",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authService.getToken()}`,
      },
    };

    return axios
      .request(config)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },

  async get_aois() {
    const config = {
      method: "get",
      url: URL_BASE + "/core/aois",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authService.getToken()}`,
      },
    };

    return axios
      .request(config)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
};

export default coreService;
