import axios from "axios";
import { jwtDecode } from "jwt-decode";

const TOKEN_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY;

const authService = {
  // Llamada HTTP para obtener el token
  async login(email, password) {
    let URL_BASE =
      process.env.REACT_APP_API_URL_AUTH + "/amiantic/v1.0/api/auth/login";

    let config = {
      method: "post",
      data: { email, password },
      url: URL_BASE,
      headers: {
        Accept: "*/*",
      },
    };

    return axios
      .request(config)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return false;
      });
  },
  getRoles() {
    const token = this.getToken();
    if (token == null) {
      return null;
    }
    const decoded_token = jwtDecode(token);
    const obj_jwt = JSON.parse(decoded_token["payload"]);
    if ("roles" in obj_jwt) {
      let new_roles = [];
      for (let rol of obj_jwt["roles"]) {
        new_roles.push(rol["name"]);
      }
      return new_roles;
    }
    return null;
  },
  checkIfTokenIsOk(token) {
    let decoded = jwtDecode(token);
    let now = new Date();
    let exp_date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    exp_date.setUTCSeconds(decoded["exp"]);
    return exp_date > now;
  },

  // Guardar el token en el almacenamiento local
  saveToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
  },

  // Obtener el token del almacenamiento local
  getToken() {
    const tk = localStorage.getItem(TOKEN_KEY);
    if (tk === null) {
      return null;
    }
    if (this.checkIfTokenIsOk(tk)) {
      return tk;
    } else {
      this.deleteToken();
      return null;
    }
  },

  // Eliminar el token del almacenamiento local
  deleteToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  // Verificar si el token es válido y renovarlo si es necesario
  //   async verificarToken() {
  //     const token = this.obtenerToken();
  //     if (!token) {
  //       return false;
  //     }
  //     const isValid = await this.checkIfTokenIsOk(token);
  //     if (!isValid) {
  //       // Renovar el token utilizando el refresh token
  //       const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  //       const newToken = await this.renovarToken(refreshToken);
  //       this.guardarToken(newToken);
  //     }
  //     return true;
  //   },

  //   // Renovar el token utilizando el refresh token
  //   async renovarToken(refreshToken) {
  //     const response = await axios.post('/api/refresh-token', { refreshToken });
  //     const newToken = response.data.token;
  //     return newToken;
  //   },
};

export default authService;
