const locale = {
  do_you_want_to_know_more: "¿Quieres saber más?",
  contact_us: "Contacta con nosotros",
  solutions: "Soluciones",
  asbestos_census: "Censo de amianto",
  water_leaks: "Fugas de agua",
  co2_sinks: "Sumideros de CO2",
  esg_transformation: "Transformación ESG",
  about_agforest: "Sobre AGForest",
  blog: "Blog",
  press_room: "Sala de prensa",
  success_stories: "Casos de éxito",
  about_us: "Sobre nosotros",
  contact: "Contacto",
  innovative_sme: "PYME INNOVADORA",
  valid_until: "Válido hasta el 24 de mayo de 2027",
  privacyand_cookie_policy: "Política de privacidad y cookies",
  legal_notice: "Aviso legal",
  spain: 'España'
};
export default locale;
