const locale = {
  solutions: "Solutions",
  about_us: "About Us",
  press_room: "Press Room",
  blog: "Blog",
  success_cases: "Success Cases",
  contact_us: "Contact Us",
  asbestos_census: "Asbestos Census",
  water_leaks: "Water Leaks",
  co2_sinks: "CO2 Sinks",
  esg_transformation: "ESG Transformation",
  log_out: "Log out",
};
export default locale;
