const locale = {
  header_title: "Asbestos: Census and Removal Schedule",
  txt_footer_privacity: "Privacy Policy and Cookies",
  txt_footer_legal: "Legal Notice",
  txt_description:
    "Municipalities are required to conduct a census of facilities and sites containing asbestos, including a schedule for its removal, in accordance with Law 07/2022, of April 8, on waste and contaminated soils for a circular economy. Comply with Law 07/2022 easily with our solution.\nAgforest provides a complete solution for your asbestos facility census, using artificial intelligence algorithms and satellite imagery to detect asbestos in your locality.",
  txt_description_title:
    "Census of Facilities with Asbestos Presence in Municipalities - Mandatory under Law 07/2022",
  txt_description_title_information:
    "In this tool, you can obtain a quote for asbestos remote sensing services for municipalities, as well as analyses that will help you understand and provide relevant information for your client.",
  log_in: "Login",
  password: "Password",
  user_name: "User name",
};
export default locale;
