import React from "react";
import { LinearProgress } from "@mui/material";
import "./SplashScreen.scss";

// Importa las imágenes SVG
import TextSVG from "./assets/SVG_Agforest.png"; // Cambia el path a la ruta de tu archivo SVG
import LogoSVG from "./assets/SVG_Satélite.svg"; // Cambia el path a la ruta de tu archivo SVG

const SplashScreen = () => {
  return (
    <div
      className="splash-screen"
      style={{ viewTransitionName: "splash-screen" }}
    >
      <div className="content">
        <div className="logo-container">
          <img src={LogoSVG} alt="Logo de carga" className="logo" />
          <img src={TextSVG} alt="Cargando..." className="text-svg" />
        </div>
        <LinearProgress className="progress-bar" />
      </div>
    </div>
  );
};

export default SplashScreen;
