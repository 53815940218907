import { Search } from "@mui/icons-material";

const locale = {
  contracted_projects: "Contracted Projects",
  other_products: "Other Products",
  search_projects: "Search projects/tags",
  select_an_option: "Select an option",
  no_tags_available: "No tags available",
  tag_not_found: "Tag not found",
  select_a_tag: "Select a tag",
  select_an_aoi: "Select an AOI",
  aoi_not_found: 'AOI not found',
  no_aois_available: ' No AOIs available',
  most_used_tags: 'Most used tags',
  add_tag:'Add tag',
  delete_tag: 'Delete tag',
  add_aoi:'Add AOI',
  delete_aoi: 'Delete AOI',
  search:'Search'


};
export default locale;
