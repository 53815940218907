const locale_header = {
  solutions: "Soluciones",
  about_us: "Sobre nosotros",
  press_room: "Sala de prensa",
  blog: "Blog",
  success_cases: "Casos de éxito",
  contact_us: "Contáctanos",
  asbestos_census: "Censo de amianto",
  water_leaks: "Fugas de agua",
  co2_sinks: "Sumideros de CO2",
  esg_transformation: "Transformación ESG",
  log_out: "Cerrar sesión",
};
export default locale_header;
